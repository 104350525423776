import React from "react";
import * as c from '../../constants/config.js';
import { Redirect } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function RegisterPage() {
  const initialState = {
    confirmPassword: "",
    countryId: "",
    email: "",
    password: "",
    telephone: "",
    isSubmitting: false, 
    errorMessage: null,
    redirect: false
  };
  const [data, setData] = React.useState(initialState);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (data.password.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a password",
        redirect: false
      });
      return;
    }

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let registerObject = {
      telephone: data.telephone,
      password:data.password,
      confirmPassword: data.confirmPassword,
      countryId:1,
      email: data.email,
    }
    
    var raw = JSON.stringify(registerObject);
    console.log(raw);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(c.REGISTER, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        localStorage.setItem("user", result);
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true
        });
      })
      .catch(error => { 
        console.log('error', error)
        setData({
          ...data,
          isSubmitting: false,
          //errorMessge: error.message || error.statusText
          errorMessage: error.status || error.statusText,
          redirect: false
        });
      });
  };

  const renderRedirect = () => {
      //console.log(data.redirect);
      if (data.redirect) {
          return <Redirect to='/auth/verify-phone-number'/>
      }
  };
  return (
    <>
      <div
        className="full-page register-page section-image"
        data-color="blue"
        data-image={require("assets/img/bg5.jpg").default}
      >
        <div className="content d-flex align-items-center">
          <Container>
            <Card className="card-register card-plain text-center">
              <Card.Header>
                <Row className="justify-content-center">
                  <Col md="8">
                    <div className="header-text">
                      <Card.Title as="h2">
                        Welcome to RubiMoni
                      </Card.Title>
                      <Card.Subtitle as="h4">
                        Register for free and access loans today
                      </Card.Subtitle>
                      <hr></hr>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="ml-auto" md="7" lg="5">
                    <Media>
                      <div className="media-left">
                        <div className="icon">
                          <i className="nc-icon nc-circle-09"></i>
                        </div>
                      </div>
                      <Media.Body>
                        <h4>Free Account</h4>
                        <p>
                          Here you can write a feature description for your
                          dashboard, let the users know what is the value that
                          you give them.
                        </p>
                      </Media.Body>
                    </Media>
                    <Media>
                      <div className="media-left">
                        <div className="icon">
                          <i className="nc-icon nc-preferences-circle-rotate"></i>
                        </div>
                      </div>
                      <Media.Body>
                        <h4>Awesome Performances</h4>
                        <p>
                          Here you can write a feature description for your
                          dashboard, let the users know what is the value that
                          you give them.
                        </p>
                      </Media.Body>
                    </Media>
                    <Media>
                      <div className="media-left">
                        <div className="icon">
                          <i className="nc-icon nc-planet"></i>
                        </div>
                      </div>
                      <Media.Body>
                        <h4>Global Support</h4>
                        <p>
                          Here you can write a feature description for your
                          dashboard, let the users know what is the value that
                          you give them.
                        </p>
                      </Media.Body>
                    </Media>
                  </Col>
                  <Col className="mr-auto" md="5" lg="4">
                    <Form className="form" onSubmit={handleSubmit}>
                      <Card className="card-plain">
                        <div className="card-body">
                          <Form.Group>
                            <Form.Control
                              placeholder="Phone number"
                              type="text"
                              value={data.telephone}
                              onChange={handleInputChange}
                              name="telephone"
                              id="telephone"
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              placeholder="Enter email"
                              type="email"
                              value={data.email}
                              onChange={handleInputChange}
                              name="email"
                              id="email"
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              placeholder="Password"
                              type="password"
                              value={data.password}
                              onChange={handleInputChange}
                              name="password"
                              id="password"
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              placeholder="Password Confirmation"
                              type="password"
                              value={data.confirmPassword}
                              onChange={handleInputChange}
                              name="confirmPassword"
                              id="confirmPassword"
                            ></Form.Control>
                          </Form.Group>
                        </div>
                        <div className="card-body" >
                          {data.errorMessage && (
                                <span style={{backgroundColor: "white"}} className="form-error">{data.errorMessage}</span>
                              )}
                        </div>
                        <div className="card-footer text-center">
                          <Button
                            className="btn-fill btn-neutral btn-wd"
                            type="submit"
                            variant="default"
                            disabled={data.isSubmitting ? true : false}
                          >
                            Create Free Account
                          {data.isSubmitting ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                              <span></span>
                          )}

                          </Button>
                        </div>
                      </Card>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg5.jpg").default + ")",
          }}
        ></div>
      </div>
      {renderRedirect()}
    </>
  );
}

export default RegisterPage;
