import React from 'react';

//API URL
//export const API_URL = 'http://api.hyperaccounting.com/api';
//export const API_URL = 'http://157.245.81.137:8080';
export const API_URL = 'https://rubimoni.com:8443';

//API End Points
export const OFFERS = `${API_URL}/Offers/stage/1`;
export const NEW_APPLICATION = `${API_URL}/Loans/newApplication`;
export const LOANS = `${API_URL}/Loans`;
export const REPAY_LOAN = `${API_URL}/Loans/repaid`;
export const APPROVE_LOAN = `${API_URL}/Loans/approved`;
export const GENERATE_CODE = `${API_URL}/Loans/generateCode`;
export const INITIALIZE_TRANSFER = `${API_URL}/Loans/initializeTransfer`;
export const VERIFY_BANK_ACCOUNT = `${API_URL}/Loans/verifybankaccount`;

export const REGISTER = `${API_URL}/Users/newUser`;
export const GET_PROFILE = `${API_URL}/Users/id`;
export const GET_USER_BY_TELEPHONE = `${API_URL}/Users/telephone`;
export const LOGIN = `${API_URL}/Users/login`;
export const UPDATE_PROFILE = `${API_URL}/Users/updateAccount`;
export const USER_CHANGE_PASSWORD = `${API_URL}/Users/changePassword`;
export const USER_CHANGE_PASSWORD_OTP = `${API_URL}/Users/changePasswordOTP`;
export const USER_UPLOAD_IMAGE = `${API_URL}/Users/uploadImage`;
export const USER_UPDATE_STAGE = `${API_URL}/Users/updateStage`;
export const ACTIVATE_USER = `${API_URL}/Users/activateUser`;
export const RESEND_ACTIVATION_CODE = `${API_URL}/Users/resendActivationCode`;

export const GET_BANK = `${API_URL}/Banks`;
export const UPDATE_BANK = `${API_URL}/Banks/updateAccount`;
export const ADD_BANK = `${API_URL}/Banks/addAccount`;

export const GET_GUARANTOR = `${API_URL}/Guarantor`;
export const UPDATE_GUARANTOR = `${API_URL}/Guarantor/updateGuarantor`;
export const ADD_GUARANTOR = `${API_URL}/Guarantor/addGuarantor`;

export const GET_CARD = `${API_URL}/Cards`;
export const UPDATE_CARD = `${API_URL}/Cards/Cards/updateCard`;
export const ADD_CARD = `${API_URL}/Cards/addCard`;

//Payments
export const INITIALIZE_PAYMENT = `${API_URL}/Payment/initialize`;
export const CHECK_PAYMENT = `${API_URL}/Payment/check`;
export const ALL_PAYMENTS = `${API_URL}/Payment/allpayments`;


export const BankOptions = [
    {label: "Abbey Mortgage Bank", value: "801"},
    {label:"Access Bank Nigeria", value: "044"},
    {label:"Access Bank PLC (DIAMOND)", value:"063"},
    {label:"ALAT by WEMA", value:"035A"},
    {label: "Amju Unique MFB", value:"50926"},
    {label: "ASO SAVING AND LOAN",value: "401"},
    {label: "Bainescredit MFB", value:"51229"},
    {label: "Bowen Microfinance Bank", value:"50931"},
    {label: "CEMCS Microfinance Bank", value:"50823"},
    {label: "CITIBANK",value: "023"},
    {label: "Coronation Merchant Bank", value:"559"},
    {label: "ECOBANK NIGERIA PLC",value: "050"},
    {label: "Ekondo Microfinance Bank", value:"562"},
    {label: "Eyowo", value:"50126"},
    {label: "FIRST BANK PLC",value: "011"},
    {label: "FIRST CITY MONUMENT BANK PLC",value: "214"},
    {label: "FIDELITY BANK PLC",value: "070"},
    {label: "FSDH MERCHANT BANK",value: "501"},
    {label: "Firmus MFB", value:"51314"},
    {label: "GTBANK PLC",value: "058"},
    {label: "Globus Bank", value:"00103"},
    {label: "HERITAGE BANK",value: "030"},
    {label: "Hackman Microfinance Bank", value:"51251"},
    {label: "Hasal Microfinance Bank", value:"50383"},
    {label: "Ibile Microfinance Bank", value:"51244"},
    {label: "Infinity MFB", value:"50457"},
    {label: "JAIZ BANK",value: "301"},
    {label: "KEYSTONE BANK PLC",value: "082"},
    {label: "Kuda Bank", value:"50211"},
    {label: "Lagos Building Investment Company Plc.", value:"90052"},
    {label: "Links MFB", value:"50549"},
    {label: "Mayfair MFB", value:"50563"},
    {label: "Mint MFB", value:"50304"},
    {label: "One Finance", value:"565"},
    {label: "PalmPay", value:"999991"},
    {label: "Parkway - ReadyCash",value: "311"},
    {label: "PARALLEX BANK",value: "526"},
    {label: "PAYCOM",value: "999992"},
    {label: "Petra Mircofinance Bank Plc", value:"50746"},
    {label: "Polaris Bank", value:"076"},
    {label: "PROVIDUS BANK",value: "101"},
    {label: "Rand Merchant Bank", value:"502"},
    {label: "Rubies MFB", value:"125"},
    {label: "Sparkle Microfinance Bank", value:"51310"},
    {label: "STANBIC IBTC BANK PLC",value: "221"},
    {label: "STANDARD CHARTERED BANK NIGERIA LIMITED",value: "068"},
    {label: "STERLING BANK PLC",value: "232"},
    {label: "Suntrust Bank", value:"100"},
    {label: "TAJ Bank",value: "302"},
    {label: "TCF MFB",value: "51211"},
    {label: "Titan Bank",value: "102"},
    {label: "UNION BANK OF NIGERIA PLC",value: "032"},
    {label: "UNITED BANK FOR AFRICA PLC",value: "033"},
    {label: "UNITY BANK PLC",value: "215"},
    {label: "VFD Microfinance Bank Limited", value:"566"},
    {label: "WEMA BANK PLC",value: "035"},
    {label: "ZENITH BANK PLC",value: "057"}
];