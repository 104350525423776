import React from "react";
import * as c from '../../constants/config.js';
import { Redirect } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
} from "react-bootstrap";

function LoginPage(props) {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  const initialState = {
    telephone: "",
    password: "",
    isSubmitting: false, 
    errorMessage: null,
    redirect: false
  };
  const [data, setData] = React.useState(initialState);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (data.password.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a password",
        redirect: false
      });
      return;
    }

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let loginObject = {
      telephone: data.telephone,
      password:data.password,
    }
    var raw = JSON.stringify(loginObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(c.LOGIN, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        
        localStorage.setItem('token', JSON.parse(result).Token);
        localStorage.setItem('isLoggedIn', true);

        let {Token, Telephone, Email, userId, FirstName, OtherNames, Surname} = JSON.parse(result);
        let user = {
            firstName: FirstName, 
            otherNames: OtherNames, 
            surname: Surname,
            telephone: Telephone,  
            email: Email, 
            userId: userId
        }; //Destructuring
        localStorage.setItem('user', JSON.stringify(user));
        //console.log(localStorage.getItem('user'))
        renderRedirect()
      })
      .catch(error => { 
        console.log('error', error)
        console.log('error message', error.message)
        console.log('error status', error.status)
        console.log('error statusText', error.statusText)

        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.status + " Invalid credentials ",
          redirect: false
        });
      });
  };

  const renderRedirect = () => {
      //if (data.redirect) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");
        var raw = "";
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            //body: raw,
            redirect: 'follow'
        };
        let user=JSON.parse(localStorage.getItem('user'))
        fetch(c.LOANS + "/" + user.userId, requestOptions)
            .then(response => {
            if (response.ok) {
                return response.text();
            } else {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                console.log(response.headers.get('Content-Type'));
                console.log(response.statusText);
                //throw new Error('Network response was 500');
                throw response;
            }
        })
        .then(result => {
            let result1 = JSON.parse(result);
            setData({
              ...data,
              isSubmitting: false,
              errorMessage: null,
              redirect: true
            });

            if(result1.applicationId){
              props.history.push('/admin/repay-loan')
            } else {
              props.history.push('/admin/apply-for-loan-update-profile')
            }
        })
        .catch(error => {
            console.log('error', error); 
            props.history.push('/admin/apply-for-loan')
        });
      //}
  };
  return (
    <>
      <div
        className="full-page section-image"
        data-color="blue"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form className="form" onSubmit={handleSubmit}>
                <Card className={"card-login " + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">Login</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Phone number</label>
                        <Form.Control
                          placeholder="Enter phone number"
                          type="text"
                          value={data.telephone}
                          onChange={handleInputChange}
                          name="telephone"
                          id="telephone"
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          placeholder="Password"
                          type="password"
                          value={data.password}
                          onChange={handleInputChange}
                          name="password"
                          id="password"
                        ></Form.Control>
                      </Form.Group>
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                      {/*
                      <Form.Check className="pl-0">
                        <Form.Check.Label>
                          <Form.Check.Input
                            defaultChecked
                            type="checkbox"
                          ></Form.Check.Input>
                          <span className="form-check-sign"></span>
                          Subscribe to newsletter
                        </Form.Check.Label>
                      </Form.Check>
                      */}
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button className="btn-wd" type="submit" variant="warning"
                      disabled={data.isSubmitting ? true : false}>
                      Login
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
      {/* {renderRedirect()} */}
    </>
  );
}

export default LoginPage;
