import React, { useState, useEffect } from "react";
import * as c from '../../constants/config.js';
import { Redirect, useHistory } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ApplyForLoanUpdateBank() {
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();

  const initialState = {
    userId: user == null ? ' ' : user.userId,
    accountName: "",
    accountNumber: "",
    bvn: "",
    bankName: "",

    isSubmitting: false,
    errorMessage: null,
    redirect: false,
    errorCode: 0

  };

  const [data, setData] = useState(initialState);
  
  useEffect(() => {
    var myHeaders = new Headers();
    //myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlci1hZG1pbiIsImV4cCI6MTYxNjU4NzYxOCwiaWF0IjoxNjE2NTY5NjE4fQ.oYCnThYN_aHOWGRFPxGG1Fk7-T0EGp0Q7HV2khA63SFDI8yUV4bC0JtalRV07dwoprKrf2ry2o9sGnl1oVNpWA");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    var raw = "";

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      //body: raw,
      redirect: 'follow'
    };
    fetch(c.GET_BANK + "/" + user.userId, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        let result1 = JSON.parse(result);
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: false,

          userId: data.userId,
          accountName: result1.AccountName,
          accountNumber: result1.AccountNumber,
          bvn: result1.BVN,
          bankName: result1.BankName,
        });
      })
      .catch(error => {
        console.log('error', error);
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true,
          
        });
      });

  }, [user.userId]);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (data.accountName.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter an Account Name",
        redirect: false
      });
      return;
    }
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    let requestObject = {
      UserId: user.userId,
      AccountName: data.accountName,
      AccountNumber: data.accountNumber,
      BVN: data.bvn,
      BankName: data.bankName
    }
    var raw = JSON.stringify(requestObject);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(raw);
    fetch(c.UPDATE_BANK, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        console.log(result)
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: false
        });

        //Make sure Card information is already gotten from User
        raw = "";
        requestOptions = {
            method: 'GET',
            headers: myHeaders,
            //body: raw,
            redirect: 'follow'
        };
        fetch(c.GET_CARD + "/" + user.userId, requestOptions)
        .then(response => {
          if (response.ok) {
              return response.text();
          } else {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              console.log(response.headers.get('Content-Type'));
              console.log(response.headers.get('Date'));
              console.log(response.status);
              console.log(response.statusText);
              console.log(response.type);
              console.log(response.url);
              //throw new Error('Network response was 500');
              throw response;
          }
        })
        .then(result => {
            console.log("fetching card info ...");
            history.push('/admin/apply-for-loan-update-card')
        })
        .catch(error => {
            console.log('error', error);
            history.push('/admin/apply-for-loan-add-card')
        });

        alert("Successfully created")
      })
      .catch(error => { 
        console.log('error 1', error.message)
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message,
          //errorMessage: error.status || error.statusText,
          redirect: false,
          errorCode: error.status
        });
      });
  };

  const renderRedirect = () => {
      //console.log(data.redirect);
      if (data.errorCode == 401) {
        return <Redirect to='/auth/login-page'/>
      }
      if (data.redirect) {
          return <Redirect to='/admin/dashboard'/>
      }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Update Bank</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form method="POST" onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Account Name</label>
                        <Form.Control
                          placeholder="Account Name"
                          type="text"
                          value={data.accountName}
                          onChange={handleInputChange}
                          name="accountName"
                          id="accountName"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Account Number</label>
                        <Form.Control
                          placeholder="Account Number"
                          type="text"
                          value={data.accountNumber}
                          onChange={handleInputChange}
                          name="accountNumber"
                          id="acountNumber"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>BVN</label>
                        <Form.Control
                          placeholder="BVN"
                          type="text"
                          value={data.bvn}
                          onChange={handleInputChange}
                          name="bvn"
                          id="bvn"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Bank Name</label>
                        <Form.Control
                          placeholder="Bank Name"
                          type="text"
                          value={data.bankName}
                          onChange={handleInputChange}
                          name="bankName"
                          id="bankName"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                
                  
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Update &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default ApplyForLoanUpdateBank;
