import React, { useState, useEffect } from "react";
import * as c from '../../constants/config.js';


import {
    Button,
    Card,
    Form,
    Container,
    Row,
    Col,
  } from "react-bootstrap";

function ViewPaymentDetails(props) {
    const [paymentDetails, setPaymentDetails] = useState({})
    const [error, setError] = useState({})
    const refno = props.refno

    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(c.CHECK_PAYMENT + "/" + refno, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.text();
                } else {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    console.log(response.headers.get('Content-Type'));
                    console.log(response.headers.get('Date'));
                    console.log(response.status);
                    console.log(response.statusText);
                    console.log(response.type);
                    console.log(response.url);
                    //throw new Error('Network response was 500');
                    throw response;
                }
            })
            .then(result => {
                console.log(result)
                setPaymentDetails(JSON.parse(result))
                // Alert.alert(
                //     'Check payment details',
                //     result,
                //     [{text: 'OK', onPress: () => navigation.replace("Payments")}],
                //     {cancelable: false},
                // );
            })
            .catch(error => { 
                console.log('error 1', error.message)
                setError(error.message);
            });
    }, [])

    return(
        <Container fluid style={{
            display: 'flex',
            'justify-content': 'center'
          }}>
          <Row>
            <Col md="8">
                <Card style={{
                    width: 'max-content'
                }}>
                    <Card.Header>
                        <Card.Title as="h4" style={{ fontWeight: 900 }}>Manual Repayment</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>Payment reference number:  {paymentDetails !== null && paymentDetails.refno}</Card.Title>
                        <Card.Title>Application ID: {paymentDetails !== null && paymentDetails.applicationId}</Card.Title>
                        <Card.Title>Bank Details: Access Bank A/C N0: 1473317358 JM Rubinus Ltd (Moni Manual)</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </Container>
    );
}


export default ViewPaymentDetails
