import React, { useState, useEffect } from "react";
import * as c from '../../constants/config.js';
import { Redirect, useHistory } from 'react-router-dom';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ApplyForLoanUpdateGuarantor() {
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();

  const initialState = {
    userId: user == null ? ' ' : user.userId,
    email: "",
    firstName: "",
    occupation: "",
    surname: "",
    telephone: "",
    address: "",

    isSubmitting: false,
    errorMessage: null,
    redirect: false,
    errorStatus: 0
  };

  const [data, setData] = useState(initialState);
  
  useEffect(() => {
    var myHeaders = new Headers();
    //myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlci1hZG1pbiIsImV4cCI6MTYxNjU4NzYxOCwiaWF0IjoxNjE2NTY5NjE4fQ.oYCnThYN_aHOWGRFPxGG1Fk7-T0EGp0Q7HV2khA63SFDI8yUV4bC0JtalRV07dwoprKrf2ry2o9sGnl1oVNpWA");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    var raw = "";

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      //body: raw,
      redirect: 'follow'
    };

    fetch(c.GET_GUARANTOR + "/" + user.userId, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        console.log(result);
        let result1 = JSON.parse(result);
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: false,

          userId: data.userId,
          email: result1.email,
          firstName: result1.firstName,
          occupation: result1.occupation,
          surname: result1.surname,
          telephone: result1.telephone,
          address: result1.address
        });
      })
      .catch(error => {
        console.log('error', error);
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true,
          errorCode: error.status
        });
      });

    }, [user.userId]);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

   if (data.email.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter an email",
        redirect: false
      });
      return;
    }
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    let requestObject = {
      userId: user == null ? ' ' : user.userId,
      email: data.email,
      firstName: data.firstName,
      occupation: data.occupation,
      surname: data.surname,
      telephone:data.telephone,
      address:data.address
    }
    var raw = JSON.stringify(requestObject);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(requestObject)
    fetch(c.UPDATE_GUARANTOR, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        console.log(result)
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true
        });

        
        props.history.push('/admin/repay-loan')
      })
      .catch(error => { 
        console.log('error 1', error.message)
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message,
          //errorMessage: error.status || error.statusText,
          redirect: false
        });
        console.log('error 2', data.errorMessage)
      });
  };

  const renderRedirect = () => {
      //console.log(data.redirect);
      if (data.errorCode == 401) {
        return <Redirect to='/auth/login-page'/>
      }
      if (data.redirect) {
          return <Redirect to='/admin/dashboard'/>
      }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Update Guarantor</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form method="POST" onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                          placeholder="Firstname"
                          type="text"
                          value={data.firstName}
                          onChange={handleInputChange}
                          name="firstName"
                          id="firstName"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Surname</label>
                        <Form.Control
                          placeholder="Surname"
                          type="text"
                          value={data.surname}
                          onChange={handleInputChange}
                          name="surname"
                          id="surname"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Email</label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          value={data.email}
                          onChange={handleInputChange}
                          name="email"
                          id="email"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Telephone</label>
                        <Form.Control
                          placeholder="Telephone"
                          type="telephone"
                          value={data.telephone}
                          onChange={handleInputChange}
                          name="telephone"
                          id="telephone"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Address</label>
                        <Form.Control
                          placeholder="Home Address"
                          type="text"
                          value={data.address}
                          onChange={handleInputChange}
                          name="address"
                          id="address"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Occupation</label>
                        <Form.Control
                          placeholder="Occupation"
                          type="text"
                          value={data.occupation}
                          onChange={handleInputChange}
                          name="occupation"
                          id="occupation"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Submit &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default ApplyForLoanUpdateGuarantor;
