import React, { useState } from "react";
import * as c from '../../constants/config.js';
import { Redirect } from 'react-router-dom';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function UpdateProfile() {
  const user = JSON.parse(localStorage.getItem('user'));
  const initialState = {
    userId: user.userId,
    DOB: "2021-03-12T11:56:40.979Z",
    address: "",
    city: "",
    countryId: user.Countryid,
    education: "",
    email: user.Email,
    firstName: user.FirstName != null ? user.FirstName : '',
    gender: "Male",
    otherNames: user.OtherNames != null ? user.OtherNames : '',
    states: "",
    surname: user.Surname != null ? user.Surname : '',

    isSubmitting: false,
    errorMessage: null,
    redirect: false,
    errorCode: 0
  };

  const [data, setData] = useState(initialState);
  
  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

   if (data.userId.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a UserId",
        redirect: false
      });
      return;
    }
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    let requestObject = {
      userId: data.userId,
      DOB: data.DOB,
      address: data.address,
      city: data.city,
      countryId: data.countryId,
      education: "",
      email: data.email,
      firstName: data.firstName,
      gender: data.gender,
      otherNames: "",
      state: data.states,
      surname: data.surname,
    }
    var raw = JSON.stringify(requestObject);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    //console.log(raw)
    fetch(c.UPDATE_PROFILE, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        console.log(result)
        let result1 = JSON.parse(result);
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true,

          DOB: result1.DOB,
          address: result1.address,
          city: result1.city,
          countryId: result1.countryId,
          education: result1.education,
          email: result1.email,
          firstName: result1.firstName,
          gender: result1.gender,
          otherNames: result1.otherNames,
          state: result1.state,
          surname: result1.surname,
        });

        let {telephone, email, userId, firstName, otherNames, surname} = response;
        let user = {
            firstName: firstName, 
            otherNames: otherNames, 
            surname: surname,
            telephone: telephone,  
            email: email, 
            userId: userId}; //Destructuring
        localStorage.setItem('user', user);   
        alert("Successfully updated")
      })
      .catch(error => { 
        console.log('error 1', error.message)
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message,
          //errorMessage: error.status || error.statusText,
          redirect: false,
          errorCode: error.status
        });
        console.log('error 2', data.errorMessage)
      });
  };

  const renderRedirect = () => {
      //console.log(data.redirect);
      if (data.errorCode == 401) {
        return <Redirect to='/auth/login-page'/>
      }
      if (data.redirect) {
          return <Redirect to='/admin/dashboard'/>
      }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Update User</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form method="POST" onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>UserId</label>
                        <Form.Control
                          placeholder="UserId"
                          type="text"
                          value={data.userId}
                          onChange={handleInputChange}
                          name="userId"
                          id="userId"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Email</label>
                        <Form.Control
                          placeholder="Email"
                          type="text"
                          value={data.email}
                          onChange={handleInputChange}
                          name="email"
                          id="email"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                          placeholder="Firstname"
                          type="text"
                          value={data.firstName}
                          onChange={handleInputChange}
                          name="firstName"
                          id="firstName"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Surname</label>
                        <Form.Control
                          placeholder="Surname"
                          type="text"
                          value={data.surname}
                          onChange={handleInputChange}
                          name="surname"
                          id="surname"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Address</label>
                        <Form.Control
                          placeholder="Home Address"
                          type="text"
                          value={data.address}
                          onChange={handleInputChange}
                          name="address"
                          id="address"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>City</label>
                        <Form.Control
                          placeholder="City"
                          type="text"
                          value={data.city}
                          onChange={handleInputChange}
                          name="city"
                          id="city"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="6">
                      <Form.Group>
                        <label>State</label>
                        <Form.Control
                          placeholder="State"
                          type="text"
                          value={data.states}
                          onChange={handleInputChange}
                          name="states"
                          id="states"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Submit &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default UpdateProfile;
