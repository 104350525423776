import React, { useState, useEffect } from "react";
import * as c from '../../constants/config.js';
import {Link, Redirect} from 'react-router-dom';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
   Form,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function GetLoansById() {
  const [applicationId, setApplicatinId] = useState(0);
  const user = JSON.parse(localStorage.getItem('user'));

  const initialState = {
    //loan: {},
    userId: user.userId,
    loanId: "",
    purpose: "",
    applicationId: "",
    principalAmount: 0,
    interestRate: 0,
    dateApplied: "",
    dateApproved: null,
    dateRepaid: null,
    status: "",

    isFetching: true,
    hasError: false,
    errorCode: 0,
    redirect: false
  };
  const [data, setData] = useState(initialState);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    var raw = "";

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      //body: raw,
      redirect: 'follow'
    };
    fetch(c.LOANS + "/" + user.userId, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        let result1 = JSON.parse(result);
        setData({
          ...data,
          redirect: false,
          isFetching: false,
          
          userId: data.userId,
          loanId: result1.loanId,
          purpose: result1.purpose,
          applicationId: result1.applicationId,
          principalAmount: result1.principalAmount,
          interestRate: result1.interestRate,
          dateApplied: result1.dateApplied,
          dateApproved: result1.dateApproved,
          dateRepaid: result1.dateRepaid,
          status: result1.status
        });
        
      })
      .catch(error => {
        console.log('error - I am here', error.status);
        setData({
          ...data,
          redirect: false,
          isFetching: false,
          errorCode: error.status
        });;
      });

  }, [user.userId]);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const renderRedirect = () => {
    //console.log(data.errorCode);
    if (data.errorCode == 401) {
      return <Redirect to='/auth/login-page'/>
    }
    if (data.redirect) {
      return <Redirect to='/admin/dashboard'/>
    }
  };

  return (
    <>
      <Container fluid>
        {/*
        <Row>
            <Col className="pr-1" md="6">
              {data.errorCode && (
                <span className="form-error">{data.errorCode}</span>
              )}
            </Col>
        </Row>
        */}
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Get Loans by ID</Card.Title>

                {/* <p className="card-category">
                  Here is a subtitle for this table
                </p> */}
                
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <tbody>
                    { data.errorCode ? (
                        <tr><td colSpan={9} className="error" style={{textAlign: 'center'}}>Status Code: {data.errorCode} - AN ERROR HAS OCCURED</td></tr>
                      ) : data.isFetching ? (
                        <tr><td colSpan={9} className="error" style={{textAlign: 'center'}}>Fetching ... </td></tr>
                      ) : (
                        <>
                          <tr><th className="border-0">LoanID</th><td>{data.loanId}</td></tr>
                          <tr><th className="border-0">ApplicationID</th><td>{data.applicationId}</td></tr>
                          <tr><th className="border-0">Purpose</th><td>{data.purpose} </td></tr>
                          <tr><th className="border-0">principalAmount</th><td>{data.principalAmount}</td></tr>
                          <tr><th className="border-0">interestRate</th><td>{data.interestRate}</td></tr>
                          <tr><th className="border-0">dateApplied</th><td>{data.dateApplied}</td></tr>
                          <tr><th className="border-0">dateApproved</th><td>{data.dateApproved}</td></tr>
                          <tr><th className="border-0">dateRepaid</th><td>{data.dateRepaid}</td></tr>
                          <tr><th className="border-0">status</th><td>{data.status}</td></tr>
                        </>
                      )}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default GetLoansById;
