import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from 'react-router-dom';

//import * as loanRequirements from "../../services/loanRequirements";
import * as c from '../../constants/config.js';
import { ToastContainer, toast } from 'react-toastify';

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ApplyForLoan() {
  const user = JSON.parse(localStorage.getItem('user'));

  const initialState = {
    offers: [  { "interest": "",
    "loanId": "",
    "principalAmount": "",
    "purpose": "",
    "userId": ""}],

    isSubmitting: false,
    errorMessage: null,
    redirect: false,
    errorCode: 0,

    hasProfileInfo: true,
    hasGuarantorInfo: true,
    hasBankInfo: true,
    hasCardInfo: true
  };

  const [data, setData] = useState(initialState);

  //useEffect(() => {
  useLayoutEffect(() => {
    getOffers()
  }, [user.userId]);

  const getOffers = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    var raw = "";

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      //body: raw,
      redirect: 'follow'
    };
    fetch(c.OFFERS, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        let result1 = JSON.parse(result);
        console.log("Fetching offer ....");
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: false,

          offers: result1,
        });

       
      })
      .catch(error => {
        console.log('error', error);
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true,
          errorCode: error.status,
        });
      });

  }

  const showError = (error) => {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    let requestObject = {
      interest: data.offers[0].interest,
      loanId: data.offers[0].LoanId,
      principalAmount: data.offers[0].principal,
      purpose: "Kuala Lumpur, Malaysia",
      userId: user.userId
    }
    var raw = JSON.stringify(requestObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(requestObject)
    fetch(c.NEW_APPLICATION, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(data => {
        showSuccess("Opened New Application")
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({"applicationid": data.ApplicationID});
        console.log(c.GENERATE_CODE + " " + JSON.stringify(raw));
        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(c.GENERATE_CODE, requestOptions)
        .then(response => response.json())
        .then(generateCoderesult => {
            showSuccess("Code Generated")
            console.log(generateCoderesult)
            //Approve the loan 
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
            myHeaders.append("Content-Type", "application/json");
            //let raw = JSON.stringify({"applicationId":data.ApplicationID});
            //console.log(c.APPROVE_LOAN + " " + JSON.stringify(raw));
            console.log(JSON.parse(data).ApplicationID)
            let raw = JSON.stringify({
              "applicationId": JSON.parse(data).ApplicationID,
              "loancode": generateCoderesult.loancode
            });
            //console.log(raw)
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(c.APPROVE_LOAN, requestOptions)
            .then(response => response.json())
            .then(approveLoanResult => {
                showSuccess("Approval Requested")
                //console.log(approveLoanResult)
                
                //Get Bank Details
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    //body: raw,
                    redirect: 'follow'
                };
                fetch(c.GET_BANK + "/" + user.userId, requestOptions)
                .then(response => response.json())
                .then(bankResult => {
                    console.log(bankResult);

                    //Initialize Transfer
                    let myHeaders = new Headers();
                    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
                    myHeaders.append("Content-Type", "application/json");
                    let raw = JSON.stringify({
                        "accountname": bankResult.AccountName,
                        "accountnumber": bankResult.AccountNumber,
                        "applicationid": JSON.parse(data).ApplicationID,
                        "bankcode": bankResult.Bankcode
                    });
                    console.log(c.INITIALIZE_TRANSFER + " " + JSON.stringify(raw));
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };
                    fetch(c.INITIALIZE_TRANSFER, requestOptions)
                    .then(response => response.json())
                    .then(initializeTransferResult => {
                        console.log(initializeTransferResult)
                        setData({
                          ...data,
                          isSubmitting: false,
                          errorMessage: null,
                          //errorMessage: error.status || error.statusText,
                          redirect: false,
                        });
                        getOffers();
                        //showSuccess("Loan Approved")
                        if (initializeTransferResult.message == "Transfer has been queued") {
                            navigation.navigate('ViewLoanDetails', {
                                principal: data.principalAmount,
                                dateApplied: data.dateApplied,
                                interest: data.interest,
                                tenure: offers[0].Tenure,
                                applicationID: data.ApplicationID,
                                purpose: purpose
                            });
                        } else {
                            console.log(initializeTransferResult)
                            showError(
                                initializeTransferResult.details,
                                initializeTransferResult.message
                            );
                            getOffers();
                        }
                    })
                    .catch(error => showError('error', error));
                })
                .catch(error => showError('error', error));
            })
            .catch(error => showError('error', error));
        })
        .catch(error => showError('error', error));
      })
      .catch(error => { 
        console.log('error 1', error.message)
        if(error.response){
          showError('Error '+error.response.status+" Status Text: ", error.response.statusText," Type: ",error.response.type)
        } else {
          showError('Something went wrong')
        }
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message,
          //errorMessage: error.status || error.statusText,
          redirect: false,
        });
        
      });
  };

  const renderRedirect = () => {
      console.log("Inside renderRediret ...");
      
      if (data.errorCode == 401) {
        return <Redirect to='/auth/login-page'/>
      }
      if (data.hasProfileInfo == false) {
        console.log("Redirecting to profile " + data.hasProfileInfo)
        return <Redirect to='/admin/update-profile' />
      }
      if (data.hasGuarantorInfo == false) {
        console.log("Redirecting to guarantor " + data.hasGuarantorInfo)
        return <Redirect to='/admin/update-guarantor' />
      }
      if (data.hasBankInfo == false) {
        console.log("Redirecting to bank" + data.hasBankInfo)
        return <Redirect to='/admin/update-bank' />
      }
      if (data.hasCardInfo == false) {
        console.log("Redirecting to Card" + data.hasCardInfo)
        return <Redirect to='/admin/update-card' />
      }

      if (data.redirect) {
          return <Redirect to='/admin/get-loans-by-id'/>
      }
  };

  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">New Loan Application</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form method="POST" onSubmit={handleSubmit}>
                <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="6">
                      <table className='table'>
                        <tbody>
                          <tr><th>Stage Name</th><td>{data.offers && data.offers[0].stageName}</td></tr>
                          <tr><th>LoanID</th><td>{data.offers && data.offers[0].LoanId}</td></tr>
                          <tr><th>Tenure</th><td>{data.offers && data.offers[0].Tenure} months </td></tr>
                          <tr><th>Interest</th><td>{data.offers && data.offers[0].interest}</td></tr>
                          <tr><th>Principal</th><td>{data.offers && data.offers[0].principal}</td></tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  
                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Apply for Loan &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default ApplyForLoan;
