import React, { useState } from "react";
import * as c from '../../constants/config.js';
import {Link, Redirect} from 'react-router-dom';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
   Form,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function CheckPayment() {
  const user = JSON.parse(localStorage.getItem('user'));

  const initialState = {
    refno: "",

    isSubmitting: false,
    errorMessage: null,
    isFetching: true,
    errorCode: 0
  };
  const [data, setData] = useState(initialState);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    /*
    if (applicationId.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter an Applicaiton ID",
        isFetching: false,
        hasError: false,
      });
      return;
    }
    */
    if (data.refno.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a reference number",
        isFetching: false,
        hasError: false,
      });
      return;
    }

    setData({
        ...data,
        isSubmitting: true,
        errorMessage: "",
        isFetching: true,
        hasError: false,
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      //body: raw,
      redirect: 'follow'
    };
    fetch(c.CHECK_PAYMENT + "/" + data.refno, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw response;
        }
      })
      .then(result => {
        console.log(result);
        alert(result);
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          isFetching: false,
        });
      })
      .catch(error => {
        console.log(error);
        setData({
          ...data,
          hasError: true,
          isSubmitting: false,
          errorMessage: error.message,
          isFetching: false,
          errorCode: error.status
        });
      });
  };

  const renderRedirect = () => {
    //console.log(data.errorCode);
    if (data.errorCode == 401) {
      return <Redirect to='/auth/login-page'/>
    }
  };

  return (
    <>
      <Container fluid>
      <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Check Payment</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Refno</label>
                        <Form.Control
                          placeholder=">RefNo"
                          type="text"
                          value={data.refno}
                          onChange={handleInputChange}
                          name="refno"
                          id="refno"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
              

                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>

                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Submit &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" md="6">
            {data.errorMessage && (
              <span className="form-error">{data.errorMessage}</span>
            )}
          </Col>
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default CheckPayment;
