/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
/*
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";
*/
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
//import LockScreenPage from "views/Pages/LockScreenPage.js";
import VerifyPhoneNumberPage from "views/Pages/VerifyPhoneNumberPage.js";
import AddBankAndProfile from "views/Pages/AddBankAndProfile.js";
import Logout from "views/Pages/Logout.js";

import ChangePassword from "views/UserProfile/ChangePassword"
import UpdateProfile from "views/UserProfile/UpdateProfile"
import ApplyForLoanUpdateProfile from "views/Loans/ApplyForLoanUpdateProfile";

import UploadImage from "views/UserProfile/UploadImage"
import UpdateStage from "views/UserProfile/UpdateStage"

import UpdateBank from "views/UserProfile/UpdateBank"
import AddBank from "views/UserProfile/AddBank"
import ApplyForLoanUpdateBank from "views/Loans/ApplyForLoanUpdateBank"
import ApplyForLoanAddBank from "views/Loans/ApplyForLoanAddBank"

import UpdateCard from "views/UserProfile/UpdateCard"
import AddCard from "views/UserProfile/AddCard"
import ApplyForLoanUpdateCard from "views/Loans/ApplyForLoanUpdateCard"
import ApplyForLoanAddCard from "views/Loans/ApplyForLoanAddCard"

import UpdateGuarantor from "views/UserProfile/UpdateGuarantor"
import AddGuarantor from "views/UserProfile/AddGuarantor";
import ApplyForLoanUpdateGuarantor from "views/Loans/ApplyForLoanUpdateGuarantor"
import ApplyForLoanAddGuarantor from "views/Loans/ApplyForLoanAddGuarantor"

import GetLoansById from "views/Loans/GetLoansById";
import ApplyForLoan from "views/Loans/ApplyForLoan";
import RepayLoan from "views/Loans/RepayLoan";

import InitializePayment from "views/Payments/InitializePayment";
import CheckPayment from "views/Payments/CheckPayment";
import ViewPaymentDetails from "views/Loans/ViewPaymentDetails";

var routes = [
  // {
  //   path: "/dashboard",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  // },
  {
    path: "/register-page",
    layout: "/auth",
    name: "Register",
    mini: "RP",
    component: RegisterPage,
  },
  {
    path: "/login-page",
    layout: "/auth",
    name: "Update Profile",
    mini: "UP",
    component: LoginPage,
  },
  {
    path: "/logout-page",
    layout: "/auth",
    name: "Change Password",
    mini: "LP",
    component: Logout,
  },
  {
    path: "/verify-phone-number",
    layout: "/auth",
    name: "Verify phone number page",
    mini: "LSP",
    component: VerifyPhoneNumberPage,
  },
  {
    path: "/add-bank-and-profile",
    layout: "/auth",
    name: "Add Bank and Profile details",
    mini: "ABP",
    component: AddBankAndProfile,
  },
  {
    path: "/add-bank",
    layout: "/admin",
    name: "Add Bank",
    mini: "UB",
    component: AddBank,
  },
  {
    path: "/add-card",
    layout: "/admin",
    name: "Add Card",
    mini: "UB",
    component: AddCard,
  },
  {
    path: "/add-guarantor",
    layout: "/admin",
    name: "Add Guarantor",
    mini: "UB",
    component: AddGuarantor,
  },
  {
    path: "/apply-for-loan",
    layout: "/admin",
    name: "Apply For Loan",
    mini: "",
    component: ApplyForLoan,
  },
  {
    path: "/apply-for-loan-update-bank",
    layout: "/admin",
    name: "New Loan Update Bank",
    mini: "",
    component: ApplyForLoanUpdateBank,
  },
  {
    path: "/apply-for-loan-add-bank",
    layout: "/admin",
    name: "New Loan Add Bank",
    mini: "",
    component: ApplyForLoanAddBank,
  },
  {
    path: "/apply-for-loan-update-card",
    layout: "/admin",
    name: "New Loan Update Card",
    mini: "",
    component: ApplyForLoanUpdateCard,
  },
  {
    path: "/apply-for-loan-add-card",
    layout: "/admin",
    name: "New Loan Add Card",
    mini: "",
    component: ApplyForLoanAddCard,
  },
  {
    path: "/apply-for-loan-update-guarantor",
    layout: "/admin",
    name: "New Loan Update Guarantor",
    mini: "",
    component: ApplyForLoanUpdateGuarantor,
  },
  {
    path: "/apply-for-loan-add-guarantor",
    layout: "/admin",
    name: "New Loan Add Guarantor",
    mini: "",
    component: ApplyForLoanAddGuarantor,
  },
  {
    path: "/get-loans-by-id",
    layout: "/auth",
    name: "Get Loans By ID",
    mini: "",
    component: GetLoansById,
  },
  {
    collapse: true,
    path: "/loans",
    name: "LOANS",
    state: "openLoans",
    icon: "nc-icon nc-notes",
    views: [
      {
        path: "/apply-for-loan-update-profile",
        layout: "/admin",
        name: "New Loan Application",
        mini: "",
        component: ApplyForLoan,
        //component: ApplyForLoanUpdateProfile,
      },
    
      {
        path: "/repay-loan",
        layout: "/admin",
        name: "Repay Loan",
        mini: "",
        component: RepayLoan,
      },
      {
        path: "/check-payment/:refno",
        layout: "/admin",
        name: "View Payment Details",
        mini: "",
        component: ViewPaymentDetails,
      },
    ],
  },
  // {
  //   collapse: true,
  //   path: "/payments",
  //   name: "Payments",
  //   state: "openPayments",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       path: "/initialize-payment",
  //       layout: "/admin",
  //       name: "Initialize Payment",
  //       mini: "",
  //       component: InitializePayment,
  //     },
  //     {
  //       path: "/check-payment",
  //       layout: "/admin",
  //       name: "Check Payment",
  //       mini: "",
  //       component: CheckPayment,
  //     },
  //   ],
  // },

  /*
  {
    collapse: true,
    path: "/tables",
    name: "Tables",
    state: "openTables",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/regular-tables",
        layout: "/admin",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
      },
      {
        path: "/extended-tables",
        layout: "/admin",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
      },
      {
        path: "/react-table",
        layout: "/admin",
        name: "React Table",
        mini: "RT",
        component: ReactTables,
      },
    ],
  },
  */
  {
    collapse: true,
    path: "/profile",
    name: "Settings",
    state: "openProfiles",
    icon: "nc-icon nc-puzzle-10",
    views: [
      {
        path: "/update-profile",
        layout: "/admin",
        name: "Update Profile",
        mini: "",
        component: UpdateProfile,
      },
      {
        path: "/change-password",
        layout: "/admin",
        name: "Change Password",
        mini: "",
        component: ChangePassword,
      },
      {
        path: "/upload-image",
        layout: "/admin",
        name: "Upload Image",
        mini: "",
        component: UploadImage,
      },
      // {
      //   path: "/update-stage",
      //   layout: "/admin",
      //   name: "Update Stage",
      //   mini: "",
      //   component: UpdateStage,
      // },
      {
        path: "/update-bank",
        layout: "/admin",
        name: "Update Bank",
        mini: "",
        component: UpdateBank,
      },
      {
        path: "/update-card",
        layout: "/admin",
        name: "Update Card",
        mini: "",
        component: UpdateCard,
      },
      {
        path: "/update-guarantor",
        layout: "/admin",
        name: "Update Guarantor",
        mini: "",
        component: UpdateGuarantor,
      },
    ],
  },
];
export default routes;
