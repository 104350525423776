import React from "react";
import * as c from '../../constants/config.js';
import { Redirect } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Col,
  Form,
} from "react-bootstrap";

function VerifyPhoneNumberPage() {
  const initialState = {
    pin: "",
    isSubmitting: false, 
    errorMessage: null,
    redirect: false
  };
  const [data, setData] = React.useState(initialState);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleResendActivationCode = event => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestObject = {
      userId: JSON.parse(localStorage.getItem('user')).userId,
      telephone: JSON.parse(localStorage.getItem('user')).telephone,
    }
    var raw = JSON.stringify(requestObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(c.RESEND_ACTIVATION_CODE, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        const user = JSON.parse(localStorage.getItem('user'));
        user.ActivationCode = result.ActivationCode;
        localStorage.setItem("user", JSON.stringify(user));
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true
        });
      })
      .catch(error => { 
        console.log('error', error)
        setData({
          ...data,
          isSubmitting: false,
          //errorMessge: error.message || error.statusText
          errorMessage: error.status || error.statusText,
          redirect: false
        });
      });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (data.pin.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a PIN",
        redirect: false
      });
      return;
    }

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    if (data.pin == JSON.parse(localStorage.getItem('user')).ActivationCode) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: null,
        redirect: true
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let requestObject = {
        userId: JSON.parse(localStorage.getItem('user')).userId
      }
      var raw = JSON.stringify(requestObject);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch(c.ACTIVATE_USER, requestOptions)
        .then(response => {
          //response.text()
          if (response.ok) {
              return response.text();
          } else {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            console.log(response.headers.get('Content-Type'));
            console.log(response.headers.get('Date'));
            console.log(response.status);
            console.log(response.statusText);
            console.log(response.type);
            console.log(response.url);
            //throw new Error('Network response was 500');
            throw response;
          }
        })
        .then(result => {
          localStorage.setItem("user", result);
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: null,
            redirect: true
          });
        })
        .catch(error => { 
          console.log('error', error)
          setData({
            ...data,
            isSubmitting: false,
            //errorMessge: error.message || error.statusText
            errorMessage: error.status || error.statusText,
            redirect: false
          });
        });
    } else {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Wrong PIN. Try again",
        redirect: false
      });
    }
  }

  const renderRedirect = () => {
      if (data.redirect) {
          return <Redirect to='/auth/add-bank-and-profile'/>
      }
  };

  return (
    <>
       <div
        className="full-page lock-page"
        data-color="purple"
        data-image={require("assets/img/bg4.jpg").default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Card className="card-lock text-center card-plain">
                <Card.Header>
                  <br /><br /><br />
                  <Card.Title as="h3" style={{color:"white"}}>Verify your phone number</Card.Title>
                  {/*                  
                  <div className="author">
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/default-avatar.png").default}
                    ></img>
                  </div>
                  */}
                </Card.Header>
                <Form className="form" onSubmit={handleSubmit}>
                <Card.Body>
                  <Card.Title as="h4">
                   Activation Code: 
                    { JSON.parse(localStorage.getItem('user')).ActivationCode == null ? 
                      ' ' 
                      : JSON.parse(localStorage.getItem('user')).ActivationCode }</Card.Title>
                  <Form.Group>
                      <Form.Control
                        placeholder="Enter PIN"
                        type="pin"
                        value={data.pin}
                        onChange={handleInputChange}
                        name="pin"
                        id="pin"
                      ></Form.Control>
                    </Form.Group>
                  
                </Card.Body>
                <div className="card-body" >
                  {data.errorMessage && (
                        <span style={{backgroundColor: "white"}} className="form-error">{data.errorMessage}</span>
                      )}
                </div>
                <Card.Footer>
                  <Button
                    className="btn-round"
                    type="submit"
                    variant="info"
                    disabled={data.isSubmitting ? true : false}
                  >
                    Verify Phone number
                  {data.isSubmitting ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : (
                      <span></span>
                  )}

                  </Button>
                  <Button
                    className="btn-round"
                    variant="success"
                    onClick={(e) => e.preventDefault()}
                  >
                    Resend Activation Code
                  </Button>

                </Card.Footer>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg4.jpg").default + ")",
          }}
        ></div>
      </div>
      {renderRedirect()}
    </>
  );
}

export default VerifyPhoneNumberPage;
