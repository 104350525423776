import React, { useState } from "react";
import {Link, Redirect} from 'react-router-dom';

const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.setItem('isLoggedIn', false);
    localStorage.removeItem('applyForLoan');
    return <Redirect to='/' />
}

export default Logout;