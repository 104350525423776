import React, { useState } from "react";
import * as c from '../../constants/config.js';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ChangePassword() {
  const user = JSON.parse(localStorage.getItem('user'));
  const initialState = {
    userId:  user == null ? ' ' : user.userId,
    newPassword: "",
    confirmPassword: "",
    telephone:  user == null ? ' ' : user.Telephone,
    isSubmitting: false,
    errorMessage: null,
    //redirect: false
    errorCoe: 0
  };
  const [data, setData] = useState(initialState);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (data.userId.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a UserID",
        //redirect: false
      });
      return;
    }

    if (data.telephone.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a Telephone number",
        //redirect: false
      });
      return;
    }

    if (data.newPassword.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a Password",
        //redirect: false
      });
      return;
    }

    if (data.confirmPassword.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter a confirm Password",
        //redirect: false
      });
      return;
    }

    if (data.newPassword.localeCompare(data.confirmPassword)) {
      console.log(data.newPassword);
      console.log(data.confirmPassword);
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Passwords are not equal",
        //redirect: false
      });
      return;
    }

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: "",
      //redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    let loginObject = {
      userId: data.userId,
      newPassword:data.newPassword,
      confirmPassword:data.confirmPassword,
      telephone:data.telephone,
    }

    var raw = JSON.stringify(loginObject);
    console.log(raw);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(c.USER_CHANGE_PASSWORD, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        console.log(result)
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          //redirect: true
        });
        alert(result)
      })
      .catch(error => { 
        console.log('error', error)
        console.log('error message', error.message)
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message,
          //redirect: false
          errorCode: error.statu
        });
      });
  };
  const renderRedirect = () => {
    //console.log(data.errorCode);
    if (data.errorCode == 401) {
      return <Redirect to='/auth/login-page'/>
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Change Password</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>User ID</label>
                        <Form.Control
                          placeholder="User ID"
                          type="text"
                          value={data.userId}
                          onChange={handleInputChange}
                          name="userId"
                          id="userId"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Telephone</label>
                        <Form.Control
                          placeholder="Telephone"
                          type="text"
                           value={data.telephone}
                          onChange={handleInputChange}
                          name="telephone"
                          id="telephone"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          placeholder="New Password"
                          type="password"
                          value={data.newPassword}
                          onChange={handleInputChange}
                          name="newPassword"
                          id="newPassword"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="6">
                      <Form.Group>
                        <label>Confirm Password</label>
                        <Form.Control
                          placeholder="Confirm Password"
                          type="password"
                          value={data.confirmPassword}
                          onChange={handleInputChange}
                          name="confirmPassword"
                          id="confirmPassword"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>

                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Change Password &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default ChangePassword;
