import React, { useState, useEffect } from "react";
import * as c from '../../constants/config.js';
import { Redirect } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";


function AddBank() {
  const user = JSON.parse(localStorage.getItem('user'));
  const initialState = {
    userId: user.userId,
    accountName: "",
    accountNumber: "",
    bvn: "",
    bankName: "",

    isSubmitting: false,
    errorMessage: null,
    redirect: false,
    errorCode: 0
  };

  const [data, setData] = useState(initialState);
  
  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

   if (data.accountName.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter an Account Name",
        redirect: false
      });
      return;
    }
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    let requestObject = {
      UserId: user.userId,
      AccountName: data.accountName,
      AccountNumber: data.accountNumber,
      BVN: data.bvn,
      BankName: data.bankName
    }
    var raw = JSON.stringify(requestObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(requestObject)
    fetch(c.ADD_BANK, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        console.log(result)
        
              
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "accountno": data.accountNumber,
            "bankcode": data.bankName,
            "userid": data.userId});
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(c.VERIFY_BANK_ACCOUNT, requestOptions)
        .then(response => response.json())
        .then(verifyBankAccountResult => {
            console.log(verifyBankAccountResult);
            
            if (verifyBankAccountResult.message == 'Account Verified')  {
                /*****
                 * Update profile
                ******/
                console.log(result)
                
                setData({
                  ...data,
                  isSubmitting: false,
                  errorMessage: null,
                  redirect: true
                });

                alert("Successfully created")
            } else {
                let updateData = {
                    userId: data.userId,
                    email: data.email,
                    firstName: data.AccountName,
                    surname: "",
                    otherNames: ""
                }
                let responseUpdateProfile = api.updateProfile(updateData);
                alert("Account could not be verified ",verifyBankAccountResult.message)
                setData({
                  ...data,
                  isSubmitting: false,
                  errorMessage: null,
                  redirect: true
                });
            }
        })
        .catch(error => {
          alert("Account could not be verified ")
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: null,
            redirect: true
          });
        });
    })
    .catch(error => { 
        console.log('error 1', error.message)
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message,
          redirect: false
        });
    });
  };

  const renderRedirect = () => {
      //console.log(data.redirect);
      if (data.errorCode == 401) {
        return <Redirect to='/auth/login-page'/>
      }
      if (data.redirect) {
          return <Redirect to='/admin/dashboard'/>
      }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Add Bank</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form method="POST" onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Account Name</label>
                        <Form.Control
                          placeholder="Account Name"
                          type="text"
                          value={data.accountName}
                          onChange={handleInputChange}
                          name="accountName"
                          id="accountName"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Account Number</label>
                        <Form.Control
                          placeholder="Account Number"
                          type="text"
                          value={data.accountNumber}
                          onChange={handleInputChange}
                          name="accountNumber"
                          id="acountNumber"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>BVN</label>
                        <Form.Control
                          placeholder="BVN"
                          type="text"
                          value={data.bvn}
                          onChange={handleInputChange}
                          name="bvn"
                          id="bvn"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                      <label for="banks">-</label>
                      <select name="bankName" className="form-control" id="banks" onChange={(e => handleInputChange(e))}>
                        <option>Select a Bank</option>
                        {
                          c.BankOptions.map((bank,i) => <option key={i} value={bank.value}>{bank.label}</option>)
                        }
                      </select>
                      </Form.Group>
                    </Col>
                  </Row>
                
                  
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Add &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );

}

export default AddBank;
