import React, { useState, useEffect } from "react";
import * as c from '../../constants/config.js';
import {Link, Redirect} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


// react-bootstrap components
import {
  Badge,
  Button,
  Card,
   Form,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ViewPaymentDetails from "./ViewPaymentDetails.js";

function RepayLoan(props) {
  const initialState = {
    applicationId: "",
    isSubmitting: false,
    errorMessage: null,
    isFetching: true,
    errorCode: 0,
    repaid: false,
    step: 1
  };
  const [data, setData] = useState(initialState);

  useEffect(() => {
    var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");
        var raw = "";
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            //body: raw,
            redirect: 'follow'
        };
        let user=JSON.parse(localStorage.getItem('user'))
        fetch(c.LOANS + "/" + user.userId, requestOptions)
            .then(response => {
            if (response.ok) {
                return response.text();
            } else {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                console.log(response.headers.get('Content-Type'));
                console.log(response.statusText);
                //throw new Error('Network response was 500');
                throw response;
            }
        })
        .then(result => {
            let result1 = JSON.parse(result);
            if(typeof result1.applicationId !== "undefined")  {
                //setDataItem(data);
                setData({
                    ...data,
                    loanId: result1.loanId,
                    purpose: result1.purpose,
                    applicationId: result1.applicationId,
                    principalAmount: result1.principalAmount,
                    interestRate: result1.interestRate,
                    dateApplied: result1.dateApplied,
                    dateApproved: result1.dateApproved,
                    dateRepaid: result1.dateRepaid,
                    status: result1.status
                });
            }
        })
        .catch(error => {
            console.log('error', error); 
        });
  },[])

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (data.applicationId.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter an Applicaiton ID",
        isFetching: false,
        hasError: false,
      });
      return;
    }

    setData({
        ...data,
        isSubmitting: true,
        errorMessage: "",
        isFetching: true,
        hasError: false,
    });

    var myHeaders = new Headers();
    let user=JSON.parse(localStorage.getItem('user'))
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "appid": data.applicationId,
      "email": user.email,
      "userid": user.userId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(c.INITIALIZE_PAYMENT, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(result => {
        console.log(result);
        //const supported = Linking.canOpenURL(result.auth_url);
        //if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            window.open(result.auth_url, '_blank', 'noopener,noreferrer')
            //Linking.openURL(result.auth_url);
        // } else {
        //     alert(`Don't know how to open this URL: ${result.auth_url}`);
        // }
        
        //alert(JSON.stringify(result));
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          isFetching: false,
          refno: result.refno,
          repaid: true
        });
        return <Redirect to={`/check-payment/${result.refno}`} />
      })
      .catch(error => {
        //console.log(error, error.response);
        if(error.response){
          showError('Error '+error.response.status+" Status Text: ", error.response.statusText," Type: ",error.response.type)
        } else {
          showError('Something went wrong')
        }
        setData({
          ...data,
          hasError: true,
          isSubmitting: false,
          errorMessage: error.message,
          isFetching: false,
          errorCode: error.status
        });
      });
  };

  const renderRedirect = () => {
    //console.log(data.errorCode);
    if (data.errorCode == 401) {
      return <Redirect to='/auth/login-page'/>
    }
  };

  const moveStep = (event) => {
    handleSubmit(event)
  }

  const showError = (error) => {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  return (
    <>
      <ToastContainer />
      {!data.repaid ? 
      <Container fluid style={{
        display: 'flex',
        'justify-content': 'center'
      }}>
      <Row>
          <Col md="8">
            <Card style={{
              width: 'max-content'
            }}>
              <Card.Header>
                <Card.Title as="h4">Repayment</Card.Title>
              </Card.Header>
              <Card.Body>
              {data.step === 1 && 
                <Repayment
                  amount={data.principalAmount}
                  date={data.dateApproved}
                  repay={moveStep}
                  loanId={data.applicationId}
                  data={data}
                />}
                {data.step === 2 && 
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Application ID</label>
                        <Form.Control
                          placeholder=">Application ID"
                          type="text"
                          value={data.applicationId}
                          onChange={handleInputChange}
                          name="applicationId"
                          id="applicationId"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>

                  <Button 
                    type="submit"
                    variant="success"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Submit &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>
                    <Button 
                      type="submit"
                      variant="danger"
                      className="ml-2 btn-fill pull-right" 
                      type="submit" 
                      onClick={() => moveStep(1)}
                      disabled={data.isSubmitting ? true : false}>
                        Cancel &nbsp;&nbsp;&nbsp;
                    </Button>
                  <div className="clearfix"></div>
                </Form>
              }
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" md="6">
            {data.errorMessage && (
              <span className="form-error">{data.errorMessage}</span>
            )}
          </Col>
        </Row>
      </Container>
      :
        <ViewPaymentDetails refno={data.refno} />
      }
      {renderRedirect()}
    </>
  );
}

const Repayment = (props) => {
  const {amount, date, repay, loanId, data} = props

  return(
    <Card className="text-center">
      <Card.Body>
        <Card.Title style={{
            fontWeight: 900,
            color: 'green',
            fontSize: '24pt'
          }}
          className='mb-4'
        >N {amount}</Card.Title>
        <Card.Title>Date Approved: {date}</Card.Title>
        <Card.Text
          className='mt-1'
          >
          Repayment ID: {loanId}
        </Card.Text>
        <Button
          variant="success"
          onClick={(e) => {
            repay(e)
          }}
          >
            {data.isSubmitting ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                <span></span>
            )}
             Repay</Button>
      </Card.Body>
      <Card.Footer className="text-muted">
        Higher loan amounts and duration up to N1,000 up to 6 months loan. Discounts up to 5% <a href="#">Learn More</a>  
      </Card.Footer>
    </Card>
  );
}

export default RepayLoan;
