import React, { useState, useEffect } from "react";
import * as c from '../../constants/config.js';
import { Redirect, useHistory } from "react-router-dom";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ApplyForLoanAddCard() {
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();

  const initialState = {
    userId: user.userId,
    cardName: "",
    cardNumber: "",
    cardType: "",
    expiryMonth: "",
    expiryYear: "",
    securityNumber: "",

    isSubmitting: false,
    errorMessage: null,
    redirect: false,
    errorCode: 0
  };

  const [data, setData] = useState(initialState);
  

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

   if (data.cardName.length === 0) {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Please enter an Account Name",
        redirect: false
      });
      return;
    }
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
      redirect: false
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");

    let requestObject = {
      UserId: user.userId,
      CardName: data.cardName,
      CardNumber: data.cardNumber,
      CardType: data.cardType,
      ExpiryMonth: data.expiryMonth,
      ExpiryYear: data.expiryYear, 
      SecurityNumber: data.securityNumber

    }
    var raw = JSON.stringify(requestObject);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(requestObject)
    fetch(c.ADD_CARD, requestOptions)
      .then(response => {
        //response.text()
        if (response.ok) {
            return response.text();
        } else {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          console.log(response.headers.get('Content-Type'));
          console.log(response.headers.get('Date'));
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.type);
          console.log(response.url);
          //throw new Error('Network response was 500');
          throw response;
        }
      })
      .then(result => {
        console.log(result)
        
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
          redirect: true
        });

        //Make sure other Guarantor information is already gotten from User
        fetch(c.GET_GUARANTOR + "/" + user.userId, requestOptions)
        .then(response => {
          if (response.ok) {
              return response.text();
          } else {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              console.log(response.headers.get('Content-Type'));
              console.log(response.headers.get('Date'));
              console.log(response.status);
              console.log(response.statusText);
              console.log(response.type);
              console.log(response.url);
              //throw new Error('Network response was 500');
              throw response;
          }
        })
        .then(result => {
            console.log("fetching guarantor info ...");
            history.push("apply-for-loan-update-guarantor")
            console.log("Finished fetching guarantor info ...")
        })
        .catch(error => {
            console.log('error', error);
            history.push("apply-for-loan-add-guarantor")
        });

        alert("Successfully created")
      })
      .catch(error => { 
        console.log('error 1', error.message)
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message,
          //errorMessage: error.status || error.statusText,
          redirect: false,
          errorCode: error.status
        });
        //console.log('error 2', data.errorMessage)
      });
  };

  const renderRedirect = () => {
      //console.log(data.redirect);
      if (data.errorCode == 401) {
        return <Redirect to='/auth/login-page'/>
      }
      if (data.redirect) {
          return <Redirect to='/admin/dashboard'/>
      }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Add Card</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form method="POST" onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Card Name</label>
                        <Form.Control
                          placeholder="Card Name"
                          type="text"
                          value={data.cardName}
                          onChange={handleInputChange}
                          name="cardName"
                          id="cardName"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Card Number</label>
                        <Form.Control
                          placeholder="Card Number"
                          type="text"
                          value={data.cardNumber}
                          onChange={handleInputChange}
                          name="cardNumber"
                          id="cardNumber"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Card Type</label>
                        <Form.Control
                          placeholder="Card Type"
                          type="text"
                          value={data.cardType}
                          onChange={handleInputChange}
                          name="cardType"
                          id="cardType"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Expiry Month</label>
                        <Form.Control
                          placeholder="Expiry Month"
                          type="text"
                          value={data.expiryMonth}
                          onChange={handleInputChange}
                          name="expiryMonth"
                          id="expiryMonth"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Expiry Year</label>
                        <Form.Control
                          placeholder="Expiry Year"
                          type="text"
                          value={data.expiryYear}
                          onChange={handleInputChange}
                          name="expiryYear"
                          id="expiryYear"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                    </Col>
                  </Row>
                  <Button 
                    type="submit"
                    variant="info"
                    className="btn-fill pull-right" 
                    type="submit" 
                    disabled={data.isSubmitting ? true : false}>
                      Add &nbsp;&nbsp;&nbsp;
                      {data.isSubmitting ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                          <span></span>
                      )}
                    </Button>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>
      </Container>
      {renderRedirect()}
    </>
  );
}

export default ApplyForLoanAddCard;
